export const storageServiceInterface = `abstract class StorageService {
  Future<void> init();

  Future<void> remove(String key);

  dynamic get(String key);

  Future<void> clear();

  bool has(String key);

  Future<void> set(String? key, dynamic data);
}
`

export const serviceLocator = `final getIt = GetIt.instance;

setupServiceLocator() {
  getIt.registerSingleton<StorageService>(HiveStorageService());
}
`

export const callStorageService = `final StorageService storageService = getIt<StorageService>();`