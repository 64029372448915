import React from "react"
import TutorialsLayout from "../../components/tutorials-layout"
import appArchitectureImage from "../../images/app-architecture.png"
import Seo from "../../components/seo"
import FolderStructure from "../../components/folder-structure/FolderStructure"
import ShareArticle from "../../components/share-article/ShareArticle"
import GetTheAppSection from "../../components/get-the-app-section/GetTheAppSection"
import PrismCode from "../../components/prims/PrismCode"
import {
  callStorageService,
  serviceLocator,
  storageServiceInterface,
} from "../../data/code/app-architecture-tutorial-code"
import widgetTree from "../../images/widget-tree.png"
import Pagination from "../../components/pagination/Pagination"
import { sidebarLinks } from "../../data"

function AppArchitecturePage() {
  return (
    <>
      <Seo
        title="App Architecture"
        titleSuffix="Dashtronaut Tutorials"
        image="https://dashtronaut.app/images/app-architecture-tutorial-preview.png"
      />
      <h1>App Architecture & Folder Structure</h1>
      <p>
        In this article, I’m going to go into details about the code of this
        slide puzzle: the app architecture, the folder structure, the
        application structure, and so on.
      </p>
      <img
        src={appArchitectureImage}
        alt="Dashtronaut Flutter Slide Puzzle App Architecture"
      />
      <h2>The App Architecture</h2>
      <p>
        Dashtronaut’s codebase is divided into 3 main layers: Data, Business
        Logic, and Presentation.
      </p>
      <h4>1. The Data Layer</h4>
      <p>
        Contains the data sources, in this case, the local storage service using{" "}
        <a
          href="https://pub.dev/packages/hive_flutter"
          target="_blank"
          rel="noreferrer"
        >
          Hive
        </a>{" "}
        and the service locator pattern to inject the service (more on that in a
        bit)
      </p>
      <p>
        This layer also contains the models, which define the data format of the
        entities in the app: Puzzle, Tile, Position, Location, and Score. Most
        of those models also handle serialization into raw data (json) that is
        stored in/fetched from local storage. This is needed for the
        functionality of storing the user’s progress and score history in local
        storage.
      </p>
      <h4>2. The Domain Layer</h4>
      <p>
        This is where most of the business logic is. Aside from some logic
        inside the models, more complex application business logic is written in
        this layer inside ChangeNotifier providers.
      </p>
      <p>
        For example, the PuzzleProvider has logic for generating the puzzle,
        updating tile locations, handling keyboard events, and so on. The
        StopWatchProvider handles the stop-watch logic. And the PhrasesProvider
        handles the logic for the phrases that Dash speaks in phrase-bubbles
        throughout the user flow.
      </p>
      <h4>3. The Presentation Layer</h4>
      <p>
        What the user sees and interacts with. It contains the UI Widgets for
        the various application modules like the background, the puzzle, the app
        drawer, Dash, and the phrases along with the styles and layout
        delegates. The ChangeNotifier providers also belong to this layer as
        they receive user input from the UI and call notifyListeners() to update
        that UI accordingly.
      </p>
      <hr className="my-5" />
      <h2>The Folder Structure</h2>
      <p>
        Let’s see how that architecture is reflected in the folder structure.
        Inside the lib folder, you will find the following folders:
      </p>
      <FolderStructure />
      <hr className="my-5" />
      <h2>Application Structure & Widget Tree</h2>
      <br />
      <img
        src={widgetTree}
        alt="Dashtronaut Flutter Slide Puzzle Widget Tree"
      />
      <hr className="my-5" />
      <h3 className="mt-4">The Service Locator Pattern</h3>
      <h5 className="mb-4">(What's happening in the services folder?)</h5>
      <p>
        The service locator pattern is a design pattern in which an abstraction
        layer is introduced when obtaining a service, and there is a central
        registry, the “Service Locator” that is responsible for registering
        those services and in turn allows you to access them from anywhere in
        your app.
      </p>
      <p>
        In practice, you create the interface of the service (the abstract
        class), and you implement this interface separately. Because the service
        locator returns the abstract interface, this separation allows for the
        ability to swap the implementation with a mock easily in testing, or
        swap it with an implementation that uses another package.
      </p>
      <p>
        Let’s see this in action. For the local storage functionality in
        dashtronaut, you can find the abstract class{" "}
        <strong>lib/services/storage/storage_service.dart</strong>
      </p>
      <PrismCode code={storageServiceInterface} />
      <p>
        This interface is implemented in the{" "}
        <strong>lib/services/storage/hive_storage_service.dart</strong> file by
        populating the methods with{" "}
        <a
          href="https://pub.dev/packages?q=hive"
          target="_blank"
          rel="noreferrer"
        >
          Hive
        </a>
        -specific functions.
      </p>
      <p>
        But how is this service accessed throughout the app? Using the{" "}
        <a
          href="https://pub.dev/packages/get_it"
          target="_blank"
          rel="noreferrer"
        >
          GetIt
        </a>{" "}
        package as a service locator. This is the code for locating the storage
        service, found in the <strong>lib/services/service_locator.dart</strong>{" "}
        file:
      </p>
      <PrismCode code={serviceLocator} />
      <p>
        After calling the <strong>setupServiceLocator</strong> function before
        your <strong>runApp</strong> function, you can call this service
        anywhere in your app like so:
      </p>
      <PrismCode code={callStorageService} />
      <p>
        (This came in very handy for me when I migrated from the
        shared_preferences to the hive package in a previous project)
      </p>
      <Pagination
        prevTutorial={sidebarLinks[0]}
        nextTutorial={sidebarLinks[3].children[0]}
      />
      <ShareArticle
        title="App Architecture, Folder Structure, and Widget Tree of Dashtronaut, the Slide Puzzle Built with Flutter for the Flutter Puzzle Hack Challenge"
        url="https://dashtronaut.app/tutorials/app-architecture"
      />
      <GetTheAppSection />
    </>
  )
}

AppArchitecturePage.Layout = TutorialsLayout

export default AppArchitecturePage
