import styled from "styled-components"

export const FolderStructureWrapper = styled.div`
  background: var(--white-color-10);
  padding: 20px;
  border-radius: 15px;

  ul {
    margin-bottom: 0;
    list-style: none;

    &:not(.children-list) {
      padding: 0;
    }
  }

  .children-list {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 10px;
      bottom: 0;
      top: 0;
      width: 2px;
      background: var(--white-color);
    }
  }

  .item-name {
    margin-left: 10px;
    font-weight: 700;
    text-transform: lowercase;
  }
`
