import React from "react"
import { FolderStructureWrapper } from "./FolderStructure.styles"
import { appFolderStructure } from "../../data"
import FolderStructureListItem from "./FolderStructureListItem"

function FolderStructure() {
  return (
    <FolderStructureWrapper>
      <ul>
        {appFolderStructure.map(item => (
          <FolderStructureListItem key={item.name} {...item} />
        ))}
      </ul>
    </FolderStructureWrapper>
  )
}

export default FolderStructure
