import React from "react"

function FolderStructureListItem({ name, type, description, children }) {
  return (
    <>
      <li className="list-item">
        {type === "file" ? (
          <i className="fas fa-file-code" />
        ) : (
          <i className="fas fa-folder" />
        )}
        <span className="item-name">{name}</span>
        {description ? (
          <span className="list-item-description">{` - ${description}`}</span>
        ) : (
          ""
        )}
      </li>
      {children ? (
        <ul className="children-list">
          {children.map(item => (
            <FolderStructureListItem key={item.name} {...item} />
          ))}
        </ul>
      ) : null}
    </>
  )
}

export default FolderStructureListItem
